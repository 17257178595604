<template>
    <div class="gondolas">
        <store-product 
            :data_products="data_products">
        </store-product>
        <!-- Footer -->
        <main-footer></main-footer>
    </div>
</template>

<script>
export default {
	metaInfo: {
		title: 'Góndolas',
		meta: [
			// Ummami Express
			{ name: 'description', content:'Fabrica de muebles para oficinas y estanterías metálicas, escritorios para oficinas, sillas para oficinas, divisiones para oficinas, estanteria metalica, bucaramanga, más de 30 años de experiencia.' },
            { name: 'keywords', content:'gondolas, góndolas, gondolas bucaramanga, góndolas bucaramanga, industrias pico, muebles oficina, muebles oficina Bucaramanga, muebles para oficina Bucaramanga, estantería metálica, estanterías metálicas, fabricas de estanterías metálicas, divisiones para oficina Bucaramanga, escritorios gerenciales Bucaramanga, escritorios para gerencia, Archivadores metálicos, fabrica de muebles para oficina, sillas para oficinas Bucaramanga, diseño de oficinas Bucaramanga, muebles para recepción Bucaramanga' },
            { name: 'author', content:'Angecia de Marketing Digital Winketing | Sebastian Báez - Leader Programming Winketing' },
			// SOCIAL
			// OpenGraph data (Most widely used)
			{property: 'og:title', content: 'Góndolas'},
			{property: 'og:site_name', content: 'Industrias Pico'},
			{property: 'og:type', content: 'website'},
			{property: 'og:url', content: 'https://industriaspico.com'},
			{property: 'og:image', content: 'https://industriaspico.com/public_sb_images/logo-industrias-pico.png'},
			{property: 'og:description', content: 'Góndolas en bucaramanga, Fabrica de muebles para oficinas y estanterías metálicas, escritorios para oficinas, sillas para oficinas, divisiones para oficinas, estanteria metalica, bucaramanga, más de 30 años de experiencia.'},
			// Twitter card    
			{name: 'twitter:card', content: 'summary'},
			{name: 'twitter:site', content: 'https://industriaspico.com'},
			{name: 'twitter:title', content: 'Góndolas'},
			{name: 'twitter:description', content: 'Fabrica de muebles para oficinas y estanterías metálicas, escritorios para oficinas, sillas para oficinas, divisiones para oficinas, estanteria metalica, bucaramanga, más de 30 años de experiencia.' },
			// Your twitter handle, if you have one.
			// {name: 'twitter:creator', content: '@alligatorio'},
			{name: 'twitter:image:src', content: 'https://industriaspico.com/public_sb_images/logo-industrias-pico.png'},
			// Google / Schema.org markup:
			{itemprop: 'name', content: 'Góndolas'},
			{itemprop: 'description', content: 'Góndolas en bucaramanga, Fabrica de muebles para oficinas y estanterías metálicas, escritorios para oficinas, sillas para oficinas, divisiones para oficinas, estanteria metalica, bucaramanga, más de 30 años de experiencia.' },
			{itemprop: 'image', content: 'https://industriaspico.com/public_sb_images/logo-industrias-pico.png'}
		]
    },
    metaInfo() {
        return {
            title: this.page_title,
        }
    },
    data() {
        return {
            page_title: 'Góndolas',
            data_products: {
                title_page: 'Góndolas para supermercado',
                types: [
                    { text: 'Isla', value: 'isla' },
                    { text: 'Lateral', value: 'lateral' },
                    { text: 'Punta de Góndola', value: 'punta-de-gondola' },
                    { text: 'Verdulero', value: 'verdulero' },
                    { text: 'Accesorios', value: 'accesorios' },
                    { text: 'Canastilla', value: 'canastilla' },
                    { text: 'Escalonada', value: 'escalonada' },
                    { text: 'Punto de pago', value: 'punto-de-pago' }
                ],
                products: [
                    {
                        name: 'Isla',
                        description: `
                            <p>
                                Diseño de entrepaños o estructura modular con bandejas por ambos lados auto soportables para la exhibición de productos. 
                                Son de alta resistencia, ideales para formar pasillos, dependiendo del espacio o el diseño de cada cliente. Muy apropiadas para supermercados, 
                                farmacias, tiendas de mascotas y otros. Diseñadas con espaldar o sin espaldar. Su acabado es en pintura en polvo horneable, electrostática. 
                            </p>
                        `,
                        url_image: 'mobiliario-de-supermercado/gondolas/productos/isla/isla_1.jpg',
                        slides: [
                             'mobiliario-de-supermercado/gondolas/productos/isla/isla_1.jpg',
                             'mobiliario-de-supermercado/gondolas/productos/isla/isla_2.jpg',
                             'mobiliario-de-supermercado/gondolas/productos/isla/isla_3.jpg',
                             'mobiliario-de-supermercado/gondolas/productos/isla/isla_4.jpg',
                             'mobiliario-de-supermercado/gondolas/productos/isla/isla_5.jpg',
                             'mobiliario-de-supermercado/gondolas/productos/isla/isla_6.jpg'
                        ],
                        price: '',
                        tags: ['isla']
                    },
                    {
                        name: 'Lateral',
                        description: `
                            <p>
                                Diseño de estructura metálica de alta resistencia, con entrepaño o bandejas por un solo lado. Dicha góndola tiene como principio poder 
                                ensamblarse unas con otras creando una góndola con variedad en su número de cuerpos. Diseñadas con espaldar o sin espaldar. Su acabado 
                                es en pintura en polvo horneable, electrostática. Las bandejas tambien pueden ser en rejilla o en madera.
                            </p>
                        `,
                        url_image: 'mobiliario-de-supermercado/gondolas/productos/lateral/lateral_1.jpg',
                        slides: [
                            'mobiliario-de-supermercado/gondolas/productos/lateral/lateral_1.jpg',
                            'mobiliario-de-supermercado/gondolas/productos/lateral/lateral_2.jpg',
                            'mobiliario-de-supermercado/gondolas/productos/lateral/lateral_3.jpg',
                            'mobiliario-de-supermercado/gondolas/productos/lateral/lateral_4.jpg'
                        ],
                        price: '',
                        tags: ['lateral']
                    },
                    {
                        name: 'Punta de góndola',
                        description: `
                            <p>
                                Estructura metalica de alta resistencia del extremo o punta de la isla central con entrepaños o bandejas frontales. 
                                Su objetivo es exponer un solo producto o productos complementarios de gran demanda. Su acabado es en pintura en polvo horneable, 
                                electrostática. Las bandejas tambien pueden ser en rejilla o en madera.
                            </p>
                        `,
                        url_image: 'mobiliario-de-supermercado/gondolas/gondola_2.jpeg',
                        slides: [
                            'mobiliario-de-supermercado/gondolas/gondola_2.jpeg'
                        ],
                        price: '',
                        tags: ['punta-de-gondola']
                    },
                    {
                        name: 'Verdulero',
                        description: `
                            <p>
                                Góndola exhibidora de frutas, verduras y demás. Diseño metálico con acabados en pintura electrostática indíquenos sus especificaciones y 
                                las fabricaremos según su necesidad.
                            </p>
                        `,
                        url_image: 'mobiliario-de-supermercado/gondolas/productos/verdulero/verdulero_1.jpg',
                        slides: [
                            'mobiliario-de-supermercado/gondolas/productos/verdulero/verdulero_1.jpg',
                            'mobiliario-de-supermercado/gondolas/productos/verdulero/verdulero_2.jpg',
                            'mobiliario-de-supermercado/gondolas/productos/verdulero/verdulero_3.jpg',
                            'mobiliario-de-supermercado/gondolas/productos/verdulero/verdulero_4.jpg'
                        ],
                        price: '',
                        tags: ['verdulero']
                    },
                    {
                        name: 'Panel lateral',
                        description: `
                            <p>
                                Superficie de madera que se instala en los laterales de las góndolas que finalizan una sección,
                                con el fin de evitar la caída hacia el costado de los productos exhibidos.
                            </p>
                            <p>
                                A la vez esta superficie permite instalar impresos o gráficas de publicidad.
                            </p>
                        `,
                        url_image: 'mobiliario-de-supermercado/gondolas/productos/accesorios/panel-madera/panel_1.jpg',
                        slides: [
                            'mobiliario-de-supermercado/gondolas/productos/accesorios/panel-madera/panel_1.jpg',
                            'mobiliario-de-supermercado/gondolas/productos/accesorios/panel-madera/panel_2.jpg'
                        ],
                        price: '',
                        tags: ['accesorios']
                    },
                    {
                        name: 'Pescador',
                        description: `
                            <p>
                                Elemento de anclaje en la torre de la góndola que permite soportar un impreso gráfico
                                que indica promociones o información relevante de los productos exhibidos en esa zona.
                            </p>
                        `,
                        url_image: 'mobiliario-de-supermercado/gondolas/productos/accesorios/pescador/pescador_1.jpg',
                        slides: [
                            'mobiliario-de-supermercado/gondolas/productos/accesorios/pescador/pescador_1.jpg',
                            'mobiliario-de-supermercado/gondolas/productos/accesorios/pescador/pescador_2.jpg'
                        ],
                        price: '',
                        tags: ['accesorios']
                    },
                   {
                        name: 'Canastilla',
                        description: `
                            <p>
                                Góndola de exhibición con niveles graduables en altura y ruedas para facilitar su movilidad.
                                Recubrimiento en pintura electrostática de alta resistencia y corrosión.
                            </p>
                            <p>
                                Niveles compuestos por canastilla para la ubicación de los productos y permiten facilidad de limpieza.
                            </p>
                            <p>
                                Portaprecios plásticos de ancalje que garantizan una mayor durabilidad.
                            </p>
                        `,
                        url_image: 'mobiliario-de-supermercado/gondolas/productos/canastilla-ruedas/canasta_ruedas_1.jpg',
                        slides: [
                            'mobiliario-de-supermercado/gondolas/productos/canastilla-ruedas/canasta_ruedas_1.jpg',
                            'mobiliario-de-supermercado/gondolas/productos/canastilla-ruedas/canasta_ruedas_2.jpg',
                            'mobiliario-de-supermercado/gondolas/productos/canastilla-ruedas/canasta_ruedas_3.jpg',
                            'mobiliario-de-supermercado/gondolas/productos/canastilla-ruedas/canasta_ruedas_4.jpg'
                        ],
                        price: '',
                        tags: ['canastilla']
                    },
                    {
                        name: 'Escalonada',
                        description: `
                            <p>
                                Góndola con niveles de diferentes profundidades y graduables en altura, que permiten exhibir productos
                                de acuerdo a su relevancia y tamaño.
                            </p>
                            <p>
                                Niveles con bandejas en malla que permiten facilidad de limpieza, y son de fácil anclaje.
                            </p>
                        `,
                        url_image: 'mobiliario-de-supermercado/gondolas/productos/escalonada/escalonada_1.jpg',
                        slides: [
                            'mobiliario-de-supermercado/gondolas/productos/escalonada/escalonada_1.jpg',
                            'mobiliario-de-supermercado/gondolas/productos/escalonada/escalonada_2.jpg',
                            'mobiliario-de-supermercado/gondolas/productos/escalonada/escalonada_3.jpg'
                        ],
                        price: '',
                        tags: ['escalonada']
                    },
                    {
                        name: 'Punto de pago',
                        description: `
                            <p>
                                Diseño metalico con acabado en pintura electrostatica. Indíquenos sus especificaciones o medidas del espacio y 
                                personalizamos su punto de pago de acuerdo a sus necesidades.
                            </p>
                        `,
                        url_image: 'mobiliario-de-supermercado/gondolas/productos/punto-de-pago/punto_1.jpg',
                        slides: [
                            'mobiliario-de-supermercado/gondolas/productos/punto-de-pago/punto_1.jpg',
                            'mobiliario-de-supermercado/gondolas/productos/punto-de-pago/punto_2.jpg',
                            'mobiliario-de-supermercado/gondolas/productos/punto-de-pago/punto_3.jpg',
                            'mobiliario-de-supermercado/gondolas/productos/punto-de-pago/punto_4.jpg',
                            'mobiliario-de-supermercado/gondolas/productos/punto-de-pago/punto_5.jpg'
                        ],
                        price: '',
                        tags: ['punto-de-pago']
                    }
                ],
            }
        }
    },
    computed: {
        url_tag() {
            return this.$route.params.tag;
        }
    },
    watch: {
        url_tag() {
            this.page_title = this.url_tag.replaceAll('-', ' ').capitalize();
        }
    }
}
</script>